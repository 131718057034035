<template>
  <!-- <nav v-if="$route.meta.headerVisible !== 'hide'">
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
  </nav> -->

  <!-- <div v-if="$route.meta.headerVisible !== 'hide'">
     <SideBar />
    <Navbar />
  </div> -->

   

  <router-view/>
</template>


<script>
// import Spinner from './components/Spinner.vue';

export default {
  // components: { Spinner },
  data() {
    return {
      loading: false
    };
  },
  methods: {
    toggleLoading() {
      this.loading = !this.loading;
    }
  }
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
