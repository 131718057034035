<template>
<footer class="content-footer footer bg-footer-theme">
    <div class="container-xxl d-flex flex-wrap justify-content-between py-2 flex-md-row flex-column">
        <div class="mb-2 mb-md-0">
            © 2024 – 2025 Box on Wheel. All rights reserved.
            <a href="https://openlogicsys.com" target="_blank" class="footer-link fw-medium"><strong>OpenLogic Systems</strong></a>
        </div>
    </div>
</footer>

</template>


<style scoped>
.footer-link{
  color: #268b61 !important;
}
</style>