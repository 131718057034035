<template>
    <div v-if="loading" class="spinner-overlay">
      <div class="spinner-border" role="status">
        <span class="sr-only"></span>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "Spinner",
    props: {
      loading: {
        type: Boolean,
        required: true,
      },
    },
  };
  </script>
  
  <style scoped>
  .spinner-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
  }
  </style>
  