import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/Page/Home.vue'

const routes = [
  {
    path: '/home',
    name: 'home',
    component: HomeView
  },
// Booking Folder routes 
 
{
  path: '/admin-booking-list',
  name: 'AdminBookingList',
  component: () => import(/* webpackChunkName: "about" */ '../views/Booking/BookingList.vue')
},

  {
    path: '/register-user-list',
    name: 'RegisterUserList',
    component: () => import(/* webpackChunkName: "about" */ '../views/Customer/UserList.vue')
  },

  {
    path: '/profile/:id',
    name: 'Profile',
    component: () => import(/* webpackChunkName: "about" */ '../views/Customer/Profile.vue')
  },
  {
    path: '/business-information/:id',
    name: 'BusinessInformation',
    component: () => import(/* webpackChunkName: "about" */ '../views/Customer/BusinessInfo.vue')
  },
  {
    path: '/company-information/:id',
    name: 'CompanyInformation',
    component: () => import(/* webpackChunkName: "company-info" */ '../views/Customer/CompanyInfo.vue')
  },
  
  {
    path: '/oprational-information/:id',
    name: 'OprationalInformation',
    component: () => import(/* webpackChunkName: "about" */ '../views/Customer/OprationalInformation.vue')
  },
// for Admin
 
  // for Admin
  {
    path: '/destination-and-location',
    name: 'DestinationAndLocation',
    component: () => import(/* webpackChunkName: "about" */ '../views/Page/Admin/Master/DestinationAndLocation.vue')
  },
// all Master Api root  write  here

{
  path: '/commodity-list',
  name: 'CommodityList',
  component: () => import(/* webpackChunkName: "about" */ '../views/Master/Commodity.vue')
},

{
  path: '/axle-list',
  name: 'AxleList',
  component: () => import(/* webpackChunkName: "about" */ '../views/Master/Axle.vue')
},

{
  path: '/taluka-list',
  name: 'TalukaList',
  component: () => import(/* webpackChunkName: "about" */ '../views/Master/Taluka.vue')
},
{
  path: '/port',
  name: 'Port',
  component: () => import(/* webpackChunkName: "about" */ '../views/Master/Ports.vue')
},

{
  path: '/empty-container-list',
  name: 'EmptyContainer',
  component: () => import(/* webpackChunkName: "about" */ '../views/Master/EmptyContainer.vue')
},
{
  path: '/ocian-carrier',
  name: 'OcianCarrier',
  component: () => import(/* webpackChunkName: "about" */ '../views/Master/OcianCarrier.vue')
},

{
  path: '/shiping-line',
  name: 'ShipingLine',
  component: () => import(/* webpackChunkName: "about" */ '../views/Master/ShipingLine.vue')
},
{
  path: '/gujrate-division',
  name: 'GujrateDivision',
  component: () => import(/* webpackChunkName: "about" */ '../views/Master/GujrateDivision.vue')
},

  {
    path: '/privecy-and-policy',
    name: 'PrivecyAndPolicy',
    component: () => import(/* webpackChunkName: "about" */ '../views/ImportantDocc/PrivecyAndPolicy.vue')
  },
  {
    path: '/turms-and-conditions',
    name: 'TurmsAndCondition',
    component: () => import(/* webpackChunkName: "about" */ '../views/ImportantDocc/TurmsAndCondition.vue')
  },
  {
    path: '/set-new-password/:random',
    name: 'SetNewPassword',
    component: () => import(/* webpackChunkName: "SetNewPassword" */ '../views/Auth/SetNewPassword.vue')
  }
,  
{
  path: '/reset-password/:random',
  name: 'ResetPassword',
  component: () => import(/* webpackChunkName: "ResetPassword" */ '../views/Auth/ResetPassword.vue')
}
,  
    {
    path: '/registeration',
    name: 'Registeration',
    component: () => import(/* webpackChunkName: "about" */ '../views/Page/Registeration.vue')
  },
  {
    path: '/register-your-account',
    name: 'Register',
    component: () => import(/* webpackChunkName: "about" */ '../views/Auth/Register.vue')
  },
  {
    path: '/',
    name: 'HomeLogin',
    component: () => import(/* webpackChunkName: "about" */ '../views/Auth/Login.vue'),
    meta: { headerVisible: 'hide' }
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "about" */ '../views/Auth/Login.vue'),
    meta: { headerVisible: 'hide' }
  },
  {
    path: '/forget-password',
    name: 'ForgetPassword',
    component: () => import(/* webpackChunkName: "about" */ '../views/Auth/ForgetPassword.vue'),
    meta: { headerVisible: 'hide' }
  },
  {
    path: '/otp-verification',
    name: 'OtpVirification.vue',
    component: () => import(/* webpackChunkName: "about" */ '../views/Auth/OtpVirification.vue'),
    meta: { headerVisible: 'hide' }
  },
  {
    path: '/mobile-otp-verification',
    name: 'MobileOtpVirification.vue',
    component: () => import(/* webpackChunkName: "about" */ '../views/Auth/MobileOtpLogin.vue'),
    meta: { headerVisible: 'hide' }
  },
  {
    path: '/error-404', name: 'Error404',
    component: () => import('@/views/Error/Error404.vue'),
    meta: {
      headerVisible: 'hide',
    },
  },{
    // path: '*',
    path: "/:catchAll(.*)", // Unrecognized path automatically matches 404
    redirect: '/error-404',
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
