<template>
  <aside id="layout-menu" class="layout-menu menu-vertical menu bg-menu-theme">
    <div class="app-brand demo">
      <router-link to="/home" class="app-brand-link">
        <img style="width:100%" src="/assets/img/logo/BOW.png" alt="">
      </router-link>
      <a href="javascript:void(0);" class="layout-menu-toggle menu-link text-large ms-auto d-block d-xl-none">
        <i class="bx bx-chevron-left bx-sm align-middle"></i>
      </a>
    </div>

    <div class="menu-inner-shadow"></div>

    <ul class="menu-inner py-1">
      <!-- Dashboards -->
      <li class="menu-item" :class="{ 'active': $route.path === '/home' }">
        <router-link to="/home" class="menu-link">
          <i class="menu-icon tf-icons bx bx-home-circle"></i>
          <div data-i18n="Dashboards">Dashboards</div>
        </router-link>
      </li>

      <!-- Containers On Wheel -->
      <li class="menu-item" :class="{ 'active': $route.path === '/register-user-list' }">
        <router-link to="/register-user-list" class="menu-link">
          <i class="menu-icon fi fi-br-ethernet"></i>
          <div data-i18n="Port">Registered Customer</div>
        </router-link>
      </li>
            <!-- Containers On Wheel -->
            <li class="menu-item" :class="{ 'active': $route.path === '/admin-booking-list' }">
        <router-link to="/admin-booking-list" class="menu-link">
          <i class="menu-icon fi fi-br-ethernet"></i>
          <div data-i18n="Port">
            Bookings
          </div>
        </router-link>
      </li>

      <!-- Master Menu -->
      <li class="menu-item" :class="{ 'active': isMenuOpen.master }">
        <a href="javascript:void(0);" class="menu-link menu-toggle" @click="toggleMenu('master')">
          <i class="menu-icon tf-icons bx bx-cog"></i>
          <div data-i18n="Master">Master</div>
        </a>
        <ul class="menu-sub master" :class="{ 'menu-open': isMenuOpen.master }">
          <!-- Settings Sub-menu -->
          <!-- <li class="menu-item" :class="{ 'active': $route.path === '#' }">
            <router-link to="/#" class="menu-link">
              <div data-i18n="Settings">Pricing</div>
            </router-link>
          </li> -->

          <li class="menu-item" :class="{ 'active': $route.path === '/gujrate-division' }">
            <router-link to="/gujrate-division" class="menu-link">
              <div data-i18n="Settings">Gujarat Division</div>
            </router-link>
          </li>

          <li class="menu-item" :class="{ 'active': $route.path === 'ocian-carrier' }">
            <router-link to="ocian-carrier" class="menu-link text-start">
              <div data-i18n="Settings">Ocean Carrier Under Contract</div>
            </router-link>
          </li>

          <li class="menu-item" :class="{ 'active': $route.path === '/shiping-line' }">
            <router-link to="/shiping-line" class="menu-link">
              <div data-i18n="Settings">Shipping Line </div>
            </router-link>
          </li>

                    
          <li class="menu-item" :class="{ 'active': $route.path === '/port' }">
            <router-link to="/port" class="menu-link text-start">
              <div data-i18n="Settings">Port</div>
            </router-link>
          </li>

          <li class="menu-item" :class="{ 'active': $route.path === '/taluka-list' }">
            <router-link to="/taluka-list" class="menu-link text-start">
              <div data-i18n="Settings">Taluka</div>
            </router-link>
          </li>

          <li class="menu-item" :class="{ 'active': $route.path === '/empty-container-list' }">
            <router-link to="/empty-container-list" class="menu-link text-start">
              <div data-i18n="Settings">Empty Container Handover Location</div>
            </router-link>
          </li>


          <li class="menu-item" :class="{ 'active': $route.path === '/commodity-list' }">
            <router-link to="/commodity-list" class="menu-link text-start">
              <div data-i18n="Settings">Commodity Type in Container</div>
            </router-link>
          </li>

          
          <!-- <li class="menu-item" :class="{ 'active': $route.path === '#' }">
            <router-link to="/#" class="menu-link text-start">
              <div data-i18n="Settings">Transporter Area</div>
            </router-link>
          </li> -->
                    
          <li class="menu-item" :class="{ 'active': $route.path === '/axle-list' }">
            <router-link to="/axle-list" class="menu-link text-start">
              <div data-i18n="Settings">Truck Axle Wight Category</div>
            </router-link>
          </li>


        
        </ul>
      </li>

      <!-- Another Menu -->
      <li class="menu-item" :class="{ 'active': isMenuOpen.another }">
        <a href="javascript:void(0);" class="menu-link menu-toggle" @click="toggleMenu('another')">
          <i class="menu-icon tf-icons bx bx-dots-horizontal-rounded"></i>
          <div data-i18n="Another">Another</div>
        </a>
        <ul class="menu-sub another" :class="{ 'menu-open': isMenuOpen.another }">
         
          <li class="menu-item" :class="{ 'active': $route.path === '/item1' }">
            <router-link to="/item1" class="menu-link">
              <div data-i18n="Item 1">Item 1</div>
            </router-link>
          </li>

        </ul>
      </li>
    </ul>
  </aside>
</template>
<script>
export default {
  data() {
    return {
      isMenuOpen: {
        master: false,
        another: false
      }
    };
  },
  methods: {
    toggleMenu(menu) {
      this.isMenuOpen[menu] = !this.isMenuOpen[menu];
      this.updateMenuState(menu);
    },
    updateMenuState(menu) {
      const menuSub = this.$el.querySelector(`.menu-sub.${menu}`);
      if (menuSub) {
        if (this.isMenuOpen[menu]) {
          menuSub.style.display = 'block';
        } else {
          menuSub.style.display = 'none';
        }
      }
    }
  },
  mounted() {
    Object.keys(this.isMenuOpen).forEach(menu => {
      this.updateMenuState(menu);
    });
  }
};
</script>
<style scoped>
.menu-sub {
  max-height: 0;
  overflow: hidden;
  transition: max-height 2s ease-in-out;
}
.menu-sub.menu-open {
  max-height: 500px; /* Set a value that is larger than the expected height of the submenu */
}



</style>
